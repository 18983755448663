@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap";
:root {
  --backColor: #76717f;
  --secondary: #47444d;
  --backColorv2: #635480;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 1.5px;
  margin: 2.2rem 0;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

body, html {
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 10px;
}

body {
  background-image: url("bit-pattern.374923b4.png");
}

.w3-content {
  max-width: 1300px;
}

p, li, button {
  font-size: 1.7rem;
  line-height: 2.9rem;
}

img {
  cursor: default !important;
}

.max_width {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.container {
  width: auto;
  margin: 0 auto;
  display: block;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    width: 1170px;
  }
}

.bcg_asphalt {
  background-color: #34495e;
}

.bcg_bitblue {
  background-color: #283440;
}

.bcg_clouds {
  background-color: #ecf0f1;
}

.bcg_midnight {
  background-color: #2c3e50;
}

.bcg_see {
  background-color: #16a085;
}

.col_asphalt {
  color: #34495e;
}

.col_belize {
  color: #2d5e89;
}

.col_clouds {
  color: #ecf0f1;
}

.col_midnight {
  color: #2c3e50;
}

.col_see {
  color: #16a085;
}

.col_bitblue {
  color: #2d5e89;
}

.quicksand {
  text-align: center;
}

.modal_max {
  max-height: 80%;
  overflow: auto;
}

.h_min300 {
  min-height: 300px;
}

.h_min480 {
  min-height: 480px;
}

.h_min510 {
  min-height: 510px;
}

.h_min570 {
  min-height: 570px;
}

.h_min630 {
  min-height: 630px;
}

.h_min660 {
  min-height: 660px;
}

.w30 {
  width: 30px;
}

.mySlides {
  display: none;
}

.w3-left, .w3-right, .w3-tag {
  cursor: pointer;
}

.w3-tag {
  width: 15px;
  height: 15px;
  margin-top: 6px;
  padding: 0;
}

ul.topnav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}

ul.topnav li {
  float: left;
}

ul.topnav li a {
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  font-size: 17px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

ul.topnav li a:hover {
  background-color: #111;
}

ul.topnav li.icon {
  display: none;
}

@media screen and (max-width: 750px) {
  ul.topnav li:not(:first-child) {
    display: none;
  }

  ul.topnav li.icon {
    display: inline-block;
  }

  ul.topnav.responsive {
    position: relative;
  }

  ul.topnav.responsive li.icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  ul.topnav.responsive li {
    float: none;
    display: inline;
  }

  ul.topnav.responsive li a {
    text-align: left;
    display: block;
  }
}

.contact-outer {
  max-width: 525px;
  margin: 0 auto;
  padding: 20px 0;
}

.contact {
  padding: 10px 30px;
}

.contact p {
  margin: 5px auto;
}

.bgimg-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media (max-width: 1024px) {
  .bgimg-1 {
    background-attachment: scroll;
  }
}

.bgimg-1 {
  background-image: url("bit-parallax.aa66e7ae.jpg");
  min-height: 100%;
}

#navibar {
  z-index: 2000;
}

.sectionAnchor {
  height: 0;
}

#company {
  padding-top: 60px;
}

#offer {
  background: url("prod_bcg.e4e5d8f2.png") top no-repeat;
  padding-top: 110px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

#googleMap {
  -webkit-filter: grayscale(60%);
  width: 100%;
  height: 400px;
}

#team {
  margin: 0 auto 70px;
}

#team .team-desc {
  background-color: #fff;
}

.cookies_page .w3-container ol li, .privacy_policy_page .w3-container ol li {
  margin: 8px auto;
}

.topnav .img-wrapper {
  line-height: 54px;
}

.topnav .img-wrapper img {
  max-height: 25px;
  margin-left: 10px;
}

.top-margin {
  margin-top: 80px;
}

footer.footer-main {
  margin-top: 30px;
  position: relative;
}

.footerContainer {
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  display: flex;
}

ul.privacy_cookies {
  color: #626262;
  margin-top: 2rem;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

ul.privacy_cookies li {
  margin-right: 10px;
  display: inline-block;
}

ul.privacy_cookies li a {
  font-weight: 700;
  text-decoration: none;
}

ul.privacy_cookies li a:hover {
  text-decoration: underline;
}

#policy-popup {
  opacity: .9;
  background-color: #f6f6f6;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#policy-popup .popup-inner {
  background-color: #f6f6f6;
  padding: 20px;
}

#policy-popup .popup-inner a {
  color: #34495e;
}

#policy-popup .popup-inner a:hover {
  color: #46627f;
}

#policy-popup .popup-inner button {
  color: #fff;
  background-color: #34495e;
  border: 0;
  border-radius: 0;
  margin-top: 10px;
  padding: 5px 15px;
}

#policy-popup .popup-inner button:hover {
  color: #000;
  background-color: #ffc107;
}

.careerContainer {
  background-color: #fbfbfb;
  padding: 3rem 0;
}

/*# sourceMappingURL=index.3cfbbed4.css.map */
